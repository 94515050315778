import { Big } from 'big.js'
import type { TFunction } from 'next-i18next'
import { mainnet, zircuit } from 'viem/chains'

type GetSelectedExternalBridgeProps = {
  bridge: string
  amount: string
  isL1ToL2: boolean
  t: TFunction
}

type GetSelectedExternalBridgeReturn = {
  url: string
  bridgeMessage: string
}

export const getSelectedExternalBridge = ({
  bridge,
  amount,
  isL1ToL2,
  t,
}: GetSelectedExternalBridgeProps): GetSelectedExternalBridgeReturn => {
  if (bridge === 'amphor') {
    return {
      url: 'https://amphor.hl.superbridge.app/',
      bridgeMessage: t('ExternalBridge.AmphorBridge'),
    }
  }

  if (bridge === 'stakestone') {
    return {
      url: 'https://app.stakestone.io/u/eth/bridge',
      bridgeMessage: t('ExternalBridge.StakestoneBridge'),
    }
  }

  if (bridge === 'stargate') {
    return {
      url: 'https://stargate.finance/bridge',
      bridgeMessage: t('ExternalBridge.StargateBridge'),
    }
  }

  if (bridge === 'free' && Big(amount || '0').gt(0.5)) {
    return {
      url: 'https://tunnel.free.tech/',
      bridgeMessage: t('ExternalBridge.FreeBridge'),
    }
  }

  if (bridge === 'free' && Big(amount || '0').lte(0.5)) {
    return {
      url: 'https://app.free.tech/',
      bridgeMessage: t('ExternalBridge.FreeBridge'),
    }
  }

  if (bridge === 're7-hyperlane') {
    return {
      url: 'https://re7lrt.hl.superbridge.app/',
      bridgeMessage: t('ExternalBridge.HyperlaneBridge'),
    }
  }

  if (bridge === 'eigenpie') {
    return {
      url: 'https://eigenlayer.magpiexyz.io/bridge',
      bridgeMessage: t('ExternalBridge.EigenpieBridge'),
    }
  }

  if (bridge === 'avalon') {
    return {
      url: 'https://usda.avalonfinance.xyz/bridge/',
      bridgeMessage: t('ExternalBridge.AvalonBridge'),
    }
  }

  if (bridge === 'steak-lrt') {
    return {
      url: 'https://steaklrt.hl.superbridge.app',
      bridgeMessage: t('ExternalBridge.SteakLrtBridge'),
    }
  }

  if (bridge === 'inception') {
    return {
      url: 'https://www.inceptionlrt.com/app/bridge',
      bridgeMessage: t('ExternalBridge.InceptionBridge'),
    }
  }

  if (bridge === 'everclear') {
    return {
      url: isL1ToL2
        ? `https://explorer.everclear.org/intents/create?tokenAddress=0xD7D2802f6b19843ac4DfE25022771FD83b5A7464&origin=${mainnet.id}&destinations=${zircuit.id}&amount=${amount.replace(',', '.')}`
        : `https://explorer.everclear.org/intents/create?tokenAddress=0x9346A5043C590133FE900aec643D9622EDddBA57&origin=${zircuit.id}&destinations=${mainnet.id}&amount=${amount.replace(',', '.')}`,
      bridgeMessage: t('ExternalBridge.EverclearBridge'),
    }
  }

  if (bridge === 'babypie') {
    return {
      url: 'https://www.babylon.magpiexyz.io/bridge',
      bridgeMessage: t('ExternalBridge.BabypieBridge'),
    }
  }

  return {
    url: 'https://renzo.hyperlane.xyz/',
    bridgeMessage: t('ExternalBridge.HyperlaneBridge'),
  }
}
