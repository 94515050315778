import { DECIMALS } from 'constants/common'
import { ETH } from 'constants/network'
import { MainnetL2USDCAdapter } from 'deployments/mainnet/L2USDCAdapter'
import { TestnetL2USDCAdapter } from 'deployments/testnet-sepolia/L2USDCAdapter'
import { env } from 'env.client'
import { RecentTransactionsListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'

export const getFormattedNumber = (value: string | number) =>
  Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 })

export const weiToEth = (value: string | number, decimals?: string | number) =>
  Number(value) / 10 ** Number(decimals ?? DECIMALS)

export const calculateGasFees = ({ gasUsed = 0, gasPrice = 0 }) => weiToEth(gasUsed * gasPrice)

export const isWstETHToken = (tokenName: string) =>
  (env.NEXT_PUBLIC_NETWORK === 'testnet' || env.NEXT_PUBLIC_NETWORK === 'mainnet') &&
  tokenName === 'wstETH'

export const isZrcToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'ZRC'

// TODO: Remove check for 'USDC.e' || 'tUSDC.eth' when we will get USDC token symbol as 'USDC' instead of 'USDC.e' || 'tUSDC.eth'.
// https://sudo-labs.slack.com/archives/C04AD3Q8JV7/p1732154450935959?thread_ts=1732104930.711369&cid=C04AD3Q8JV7
export const isUSDCToken = (tokenName: string) =>
  (env.NEXT_PUBLIC_NETWORK === 'mainnet' && (tokenName === 'USDC' || tokenName === 'USDC.e')) ||
  (env.NEXT_PUBLIC_NETWORK === 'testnet' && (tokenName === 'USDC' || tokenName === 'tUSDC.eth'))

export const isLsETHToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'LsETH'

export const isUSDTToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'USDT'

export const getUSDCAdapterAddress = () => {
  if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return MainnetL2USDCAdapter.address
  }

  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return TestnetL2USDCAdapter.address
  }

  return ''
}

export const getUSDCAdapterAbi = () => {
  if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return MainnetL2USDCAdapter.abi
  }

  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return TestnetL2USDCAdapter.abi
  }

  return ''
}

// TODO: Remove this helper and revert to previous state when we will get USDC token symbol as 'USDC' instead of 'USDC.e' || 'tUSDC.eth' and WBTC token symbol as 'WBTC' instead of 'WBTC.e'.
// https://sudo-labs.slack.com/archives/C04AD3Q8JV7/p1732154450935959?thread_ts=1732104930.711369&cid=C04AD3Q8JV7
export const getAssetSymbol = (symbol?: string) => {
  if (!symbol) {
    return ETH.symbol
  }

  if (symbol === 'WBTC.e') {
    return 'WBTC'
  }

  if (symbol === 'USDC.e' || symbol === 'tUSDC.eth') {
    return 'USDC'
  }

  return symbol
}

export const getWatchAssetSymbol = (symbol: string) => {
  if (symbol === 'WBTC' && env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return 'WBTC.e'
  }

  if (symbol === 'USDC' && env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return 'USDC.e'
  }

  if (symbol === 'USDC' && env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return 'tUSDC.eth'
  }

  return symbol
}

export const doesTransactionHaveValidExitStatus = (exitStatus?: TxItemExitStatus) =>
  [
    TxItemExitStatus.ready_to_relay,
    TxItemExitStatus.ready_to_prove,
    TxItemExitStatus.in_challenge_period,
    TxItemExitStatus.waiting,
  ].includes(exitStatus ?? '')
