import React from 'react'
import { DEFAULT_VALUE } from 'constants/common'
import { ETH } from 'constants/network'
import type { Asset, Erc20TokenAsset } from 'hooks/useAssets'
import { useAssets } from 'hooks/useAssets'
import type { EthAsset } from 'types/network'

const AssetContext = React.createContext<{
  selectedAsset: Asset
  setSelectedAsset: React.Dispatch<React.SetStateAction<Asset>>
  ethBalance: string
  selectedAssetBalance: string
  assets: Asset[]
  assetsWithBalance: ReturnType<typeof useAssets>['assetsWithBalance']
  addManualAsset: (asset: Erc20TokenAsset) => Promise<void>
  isFetchingEthBalance: boolean
  areAssetsWithBalanceLoading: boolean
  refetchAssetsWithBalance: ReturnType<typeof useAssets>['refetchAssetsWithBalance']
}>({
  selectedAsset: ETH,
  setSelectedAsset: () => {},
  ethBalance: DEFAULT_VALUE,
  selectedAssetBalance: DEFAULT_VALUE,
  addManualAsset: () => Promise.resolve(),
  assets: [ETH],
  assetsWithBalance: [
    {
      ...ETH,
      value: DEFAULT_VALUE,
    },
  ],
  isFetchingEthBalance: false,
  areAssetsWithBalanceLoading: true,
  refetchAssetsWithBalance: () => Promise.resolve(),
})

const useAssetContext = () => React.useContext(AssetContext)

type AssetProviderProps = {
  children: React.ReactNode
}

const AssetProvider = ({ children }: AssetProviderProps) => {
  const [selectedAsset, setSelectedAsset] = React.useState<Erc20TokenAsset | EthAsset>(ETH)
  const {
    assets,
    addManualAsset,
    assetsWithBalance,
    ethBalance,
    isFetchingEthBalance,
    areAssetsWithBalanceLoading,
    refetchAssetsWithBalance,
  } = useAssets()

  const selectedAssetBalance = React.useMemo(
    () =>
      assetsWithBalance.find(({ symbol }) => symbol === selectedAsset?.symbol)?.value ??
      DEFAULT_VALUE,
    [assetsWithBalance, selectedAsset],
  )

  const contextValues = React.useMemo(
    () => ({
      selectedAsset,
      setSelectedAsset,
      ethBalance,
      selectedAssetBalance,
      assets,
      assetsWithBalance,
      addManualAsset,
      isFetchingEthBalance,
      areAssetsWithBalanceLoading,
      refetchAssetsWithBalance,
    }),
    [
      selectedAsset,
      setSelectedAsset,
      ethBalance,
      selectedAssetBalance,
      assets,
      assetsWithBalance,
      addManualAsset,
      isFetchingEthBalance,
      areAssetsWithBalanceLoading,
      refetchAssetsWithBalance,
    ],
  )

  return <AssetContext.Provider value={contextValues}>{children}</AssetContext.Provider>
}

export { AssetProvider, useAssetContext }
