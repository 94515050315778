import {
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type BridgeApproveModalProps = {
  reason: string
  isOpen: boolean
  onClose: () => void
}

export const BridgeQuarantinedModal = ({ reason, isOpen, onClose }: BridgeApproveModalProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor={COLORS.white}>
        <Flex flexDir="column" pt={10} pb={6} px={8}>
          <Flex w="100%" justifyContent="center" mb={4}>
            <Image src={`/assets/detail_not_found_${colorMode}.svg`} />
          </Flex>
          <Text variant="heading4medium" align="center" mb={1.5}>
            {t('BridgeQuarantinedModal.TransactionQuarantined')}
          </Text>
          <Text align="center">{reason}</Text>
        </Flex>
        <Divider />
        <ModalFooter px={8} py={5}>
          <Button w="100%" loadingText={t('Bridging')} onClick={onClose}>
            {t('BridgeQuarantinedModal.BackToBridge')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
