import React from 'react'

const SHOW_COPIED_TEXT_INTERVAL = 2000

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false)

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, SHOW_COPIED_TEXT_INTERVAL)
  }

  return { isCopied, copyToClipboard }
}
