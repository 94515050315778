import { omit } from 'lodash'
import { createPublicClient, defineChain, http } from 'viem'
import { publicActionsL1, publicActionsL2 } from 'viem/op-stack'
import { MULTICALL3_ADDRESS } from 'constants/common'
import { zircuit, l1 } from 'constants/network'

export const publicClientL1 = createPublicClient({
  chain: defineChain({
    ...omit(l1, ['caipNetworkId', 'chainNamespace', 'fees']),
    contracts: {
      multicall3: {
        address: MULTICALL3_ADDRESS,
      },
    },
  }),
  transport: http(undefined, {
    retryCount: 5,
    retryDelay: 200,
  }),
}).extend(publicActionsL1())

export const publicClientL2 = createPublicClient({
  chain: defineChain({
    ...omit(zircuit, ['caipNetworkId', 'chainNamespace', 'fees']),
    // Multicall3 contract address has to be added as well, otherwise build fails.
    contracts: {
      multicall3: {
        address: MULTICALL3_ADDRESS,
      },
    },
  }),
  transport: http(undefined, {
    retryCount: 5,
    retryDelay: 200,
  }),
}).extend(publicActionsL2())
