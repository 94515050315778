import {
  Button,
  Text,
  Flex,
  Image,
  type FlexProps,
  useColorMode,
  Box,
  useMediaQuery,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowLeftRightLine } from 'react-icons/ri'
import { useAccount } from 'wagmi'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { getImageNameByChainId } from 'utils/getImageNameByChainId'
import { captureError } from 'utils/sentry'

type ItemType = {
  value: { label: string; id: number }
  title: string
}

const Item = ({ value, title }: ItemType) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const [isMobile] = useMediaQuery('(max-width: 500px)')

  return (
    <Box minW="fit-content" flex={1}>
      <Text mb={1.5} variant="text3regular" color={COLORS.grey03}>
        {title}
      </Text>
      <Flex
        px={{ base: 2, md: 4 }}
        py={{ base: 2, md: 4 }}
        alignItems="center"
        justifyContent="space-between"
        bgColor={COLORS.bgPrimary}
        border="1px solid"
        borderColor={COLORS.grey06}
        borderRadius={8}
      >
        <Flex gap={{ base: 1, md: 2.5 }} alignItems="center">
          <Image
            src={`/assets/${getImageNameByChainId(value.id, colorMode)}.svg`}
            {...(isMobile && { boxSize: 5 })}
          />
          <Text variant="title2medium" maxW="6.75rem" isTruncated>
            {isMobile ? value.label.substring(0, 3).toUpperCase() : value.label}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

type Value = {
  id: number
  label: string
}

type BridgeSelectorProps = {
  from: Value
  onFromChange?: (props: Value) => void
  to: Value
  onToChange?: (props: Value) => void
  isDisabled?: boolean
  onIncorrectNetwork: () => void
} & FlexProps

export const BridgeSelector = ({
  from,
  onFromChange,
  to,
  onToChange,
  isDisabled,
  onIncorrectNetwork,
  ...props
}: BridgeSelectorProps) => {
  const COLORS = useThemeColors()
  const { chainId } = useAccount()
  const { t } = useTranslation(['common'])
  const toast = useToast()

  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Item value={from} title={t('BridgeSelector.From')} />
      {!isDisabled && (
        <Button
          mx={4}
          mt={6}
          variant="icon"
          bgColor={COLORS.bgPrimary}
          onClick={async () => {
            onFromChange?.(to)
            onToChange?.(from)
            if (chainId && chainId !== to.id) {
              try {
                onIncorrectNetwork()
              } catch (error) {
                if (error instanceof Error) {
                  captureError(error)
                  toast({
                    message: t('Error.FailedToSwitchNetwork'),
                    status: 'danger',
                  })
                }
              }
            }
          }}
        >
          <RiArrowLeftRightLine size={20} />
        </Button>
      )}
      <Item value={to} title={t('BridgeSelector.To')} />
    </Flex>
  )
}
