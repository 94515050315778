import { Link } from '@chakra-ui/next-js'
import { Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'
import { PAGE_OFFSET_X } from 'theme/constants'

export const Footer = () => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])

  return (
    <Flex as="footer" px={PAGE_OFFSET_X} py={6}>
      <Flex w="100%" alignItems="center">
        <Image
          alt={t('AriaLabel.ZircuitLogo')}
          src={`/assets/zircuit_logo_footer_${colorMode}.svg`}
        />
        <Text ml={1} variant="caption2regular" color={COLORS.grey02}>
          {t('Footer.PoweredBy')}{' '}
          <Link
            _hover={{ textDecor: 'underline', color: COLORS.zircuitPrimary }}
            href="https://www.zircuit.com/"
          >
            <Text as="span" variant="caption2regular" color={COLORS.zircuitPrimary}>
              {t('Footer.Zircuit')}
            </Text>
          </Link>
          .
        </Text>
      </Flex>
    </Flex>
  )
}
