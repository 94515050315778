import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { Trans, useTranslation } from 'next-i18next'
import React from 'react'
import type { Address } from 'viem'
import { Alert } from 'components/Alert'
import { AmountLabel } from 'components/AmountLabel'
import { BridgeSelector } from 'components/BridgeSelector'
import { Bold } from 'components/I18N'
import { InfoPopup } from 'components/InfoPopup'
import { Modal } from 'components/Modal'
import { ETH, FULL_ZIRCUIT_NETWORK_NAME, l1, zircuit } from 'constants/network'
import { env } from 'env.client'
import { useThemeColors } from 'hooks/useThemeColors'
import { useAssetContext } from 'providers/AssetProvider'
import { getTruncatedText } from 'utils/getTruncatedText'

type Value = {
  id: number
  label: string
}

type BridgeApproveModalProps = {
  from: Value
  to: Value
  isOpen: boolean
  title: string
  onClose: () => void
  amount: string
  gasFee: number | string
  isGasFeeLoading?: boolean
  onApprove: () => Promise<void>
  isPendingTransactionQuarantined: boolean
  onIncorrectNetwork: () => void
  customReceivingAddress?: Address
  estimatedL1GasFee: number | string
  isConnectedAddressMultisigWallet: boolean
  isConnectedMultisigWalletLoading: boolean
}

export const BridgeApproveModal = ({
  isOpen,
  title,
  onClose,
  from,
  to,
  amount,
  gasFee,
  isGasFeeLoading,
  onApprove,
  isPendingTransactionQuarantined,
  onIncorrectNetwork,
  customReceivingAddress,
  estimatedL1GasFee,
  isConnectedAddressMultisigWallet,
  isConnectedMultisigWalletLoading,
}: BridgeApproveModalProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { selectedAsset } = useAssetContext()
  const [isBridging, setIsBridging] = React.useState(false)

  React.useEffect(() => {
    if (isPendingTransactionQuarantined && !isOpen) {
      setIsBridging(false)
    }
  }, [isOpen, isPendingTransactionQuarantined])

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} modalContentProps={{ maxW: '600px' }}>
      <Flex
        w="100%"
        justifyContent={from.id === zircuit.id ? 'space-between' : 'center'}
        gap={4}
        bgColor={COLORS.grey07}
        border="1px solid"
        borderColor={COLORS.grey06}
        borderRadius={10}
        py={5}
        px={5}
        direction={{ base: 'column', md: 'row' }}
      >
        <Image
          margin={{ base: '0 auto', md: 'auto 0' }}
          width="78px"
          height="78px"
          src={
            (from.id === l1.id && selectedAsset.symbol !== ETH.symbol) || from.id === zircuit.id
              ? `/assets/cat_laptop.svg`
              : `/assets/zircuit2_${colorMode}.svg`
          }
        />
        {from.id === l1.id && selectedAsset.symbol !== ETH.symbol && (
          <Flex flexDirection="column" gap="4">
            <Text textAlign={{ base: 'center', md: 'left' }}>
              <Trans
                t={t}
                i18nKey="BridgeApproveModal.ZircuitDepositERC20Warning.Title"
                values={{
                  l1NetworkName:
                    env.NEXT_PUBLIC_NETWORK === 'mainnet' ? 'Ethereum Mainnet' : 'Sepolia Testnet',
                  l2NetworkName: FULL_ZIRCUIT_NETWORK_NAME,
                }}
              />
            </Text>
            <Flex flexDirection="column">
              <Text textAlign={{ base: 'center', md: 'left' }}>
                <Trans
                  t={t}
                  i18nKey="BridgeApproveModal.ZircuitDepositERC20Warning.FirstMessage"
                  components={{ Bold: <Bold /> }}
                />
              </Text>
              <Text textAlign={{ base: 'center', md: 'left' }}>
                <Trans
                  t={t}
                  i18nKey="BridgeApproveModal.ZircuitDepositERC20Warning.SecondMessage"
                  components={{ Bold: <Bold /> }}
                  values={{ l2NetworkName: FULL_ZIRCUIT_NETWORK_NAME }}
                />
              </Text>
            </Flex>
          </Flex>
        )}
        {from.id === zircuit.id && (
          <Flex flexDirection="column" gap="4">
            <Text textAlign={{ base: 'center', md: 'left' }}>
              <Trans
                t={t}
                i18nKey="BridgeApproveModal.ZircuitWithdrawalTimeWarning.Title"
                components={{ Bold: <Bold /> }}
              />
            </Text>
            <Text textAlign={{ base: 'center', md: 'left' }}>
              <Trans
                t={t}
                i18nKey="BridgeApproveModal.ZircuitWithdrawalTimeWarning.Message"
                components={{ Bold: <Bold /> }}
                values={{
                  gasValue: estimatedL1GasFee,
                }}
              />
            </Text>
          </Flex>
        )}
      </Flex>
      <BridgeSelector
        gap={5}
        mt={8}
        from={from}
        to={to}
        isDisabled
        onIncorrectNetwork={onIncorrectNetwork}
      />
      <AmountLabel
        mt={6}
        label={t('BridgeApproveModal.YouWillReceive')}
        value={`${amount} ${selectedAsset.symbol}`}
      />
      {isConnectedAddressMultisigWallet ? (
        <Flex align="center" gap={1} mt={3}>
          <AmountLabel
            w="100%"
            label={t('BridgeApproveModal.EstimatedGasFees')}
            value={t('BridgeApproveModal.Unavailable')}
          />
          <InfoPopup title={t('BridgeApproveModal.GasEstimateUnavailable')}>
            <Text>{t('BridgeApproveModal.GasEstimateUnavailableTooltip')}</Text>
          </InfoPopup>
        </Flex>
      ) : (
        <AmountLabel
          mt={3}
          label={t('BridgeApproveModal.EstimatedGasFees')}
          value={`${gasFee || 0} ${ETH.symbol}`}
          isValueLoading={isGasFeeLoading || isConnectedMultisigWalletLoading}
        />
      )}
      {customReceivingAddress && (
        <AmountLabel
          mt={2}
          label={t('BridgeApproveModal.CustomReceivingAddress')}
          value={getTruncatedText(customReceivingAddress, 10, 10)}
        />
      )}
      {from.id === zircuit.id && (
        <Alert mt={7} variant="warning">
          {t('BridgeApproveModal.ZircuitWithdrawalWarning')}
        </Alert>
      )}
      <Button
        mt={6}
        w="100%"
        isLoading={isBridging}
        loadingText={t('Bridging')}
        onClick={() => {
          const asyncFn = async () => {
            setIsBridging(true)
            await onApprove()
            setIsBridging(false)
          }

          void asyncFn()
        }}
      >
        {t('BridgeApproveModal.ApproveTransactionInYourWallet')}
      </Button>
    </Modal>
  )
}
