import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { bsc } from '@reown/appkit/networks'
import { createAppKit, useAppKit, useAppKitAccount } from '@reown/appkit/react'
import { capitalize } from 'lodash'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { APP_URL } from 'constants/common'
import { l1, zircuit } from 'constants/network'
import { env } from 'env.client'
import { wagmiAdapter } from 'lib/wagmi'

// Create the modal
createAppKit({
  adapters: [wagmiAdapter],
  projectId: env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  networks: [zircuit, l1, bsc],
  defaultNetwork: l1,
  allowUnsupportedChain: false,
  termsConditionsUrl: 'https://static.zircuit.com/docs/tos.pdf',
  privacyPolicyUrl: 'https://static.zircuit.com/docs/pp.pdf',
  features: {
    socials: false,
    email: false,
  },
  metadata: {
    name: `Zircuit Bridge - ${capitalize(env.NEXT_PUBLIC_NETWORK)}`,
    description:
      'Experience the safest chain for DeFi and staking. Developers get powerful features; users get peace of mind.',
    url: APP_URL,
    icons: [`${APP_URL}/assets/zircuit_light.svg`],
  },
})

export const ConnectButton = ({ onClick, isLoading, ...props }: ButtonProps) => {
  const { t } = useTranslation(['common'])
  const { open } = useAppKit()
  const { status } = useAppKitAccount()

  const isConnecting = status === 'reconnecting' || status === 'connecting'

  return (
    <Button
      onClick={async (event) => {
        await open()
        onClick?.(event)
      }}
      isLoading={isLoading || isConnecting}
      {...props}
    >
      {t('ConnectButton.ConnectWallet')}
    </Button>
  )
}
