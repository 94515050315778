import Script from 'next/script'
import React from 'react'
import { useAccount } from 'wagmi'
import { META_CRM_SCRIPT } from 'constants/inlineScripts'
import { env } from 'env.client'

type MetaCRMProviderProps = React.PropsWithChildren<{
  nonceHeader: string
}>

export const MetaCRMProvider = ({ nonceHeader, children }: MetaCRMProviderProps) => {
  const { address } = useAccount()

  React.useEffect(() => {
    const connectMetaCRM = () => {
      window.MetaCRMWidget?.manualConnectWallet?.(address)
    }

    connectMetaCRM()
    document.addEventListener('MetaCRMLoaded', connectMetaCRM)

    return () => document.removeEventListener('MetaCRMLoaded', connectMetaCRM)
  }, [address])

  return (
    <>
      {process.env.NODE_ENV === 'production' && env.NEXT_PUBLIC_META_CRM_API_KEY && (
        <Script
          id="meta-crm-script"
          dangerouslySetInnerHTML={{
            __html: META_CRM_SCRIPT,
          }}
          strategy="lazyOnload"
          nonce={nonceHeader}
        />
      )}
      {children}
    </>
  )
}
