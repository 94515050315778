import { Flex, type FlexProps, IconButton, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import {
  RiArrowLeftDoubleFill,
  RiArrowLeftSLine,
  RiArrowRightDoubleFill,
  RiArrowRightSLine,
} from 'react-icons/ri'
import { COLOR_MODE, FIRST_PAGE } from 'constants/common'
import { useThemeColors } from 'hooks/useThemeColors'

type PaginationProps = {
  count: number
  currentPage: number
  onPageChange: (newPage: number) => Promise<void>
  isDisabled?: boolean
} & FlexProps

export const Pagination = ({
  count,
  currentPage,
  onPageChange,
  isDisabled = false,
  ...props
}: PaginationProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])

  const isPreviousPageDisabled = currentPage === FIRST_PAGE || isDisabled
  const isNextPageDisabled = currentPage === count || isDisabled

  const isLightMode = colorMode === COLOR_MODE.LIGHT

  const COMMON_ICON_BUTTON_PROPS = {
    ml: 1,
    fontSize: 20,
    variant: 'icon',
    border: 'none',
    color: isLightMode ? 'grey01' : 'white',
    _disabled: { color: isLightMode ? 'grey05' : 'grey03' },
  }

  return (
    <Flex alignItems="center" minW="fit-content" {...props}>
      {/* Dekstop */}
      <Text
        variant="caption2regular"
        mr={3}
        color={COLORS.grey02}
        display={{ base: 'none', md: 'block' }}
      >
        {t('Pagination.Page')}{' '}
        <Text as="span" variant="caption2medium" color={COLORS.grey02}>
          {currentPage}{' '}
        </Text>
        {t('Pagination.Of')}{' '}
        <Text as="span" variant="caption2medium" color={COLORS.grey02}>
          {count}
        </Text>
      </Text>
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        icon={<RiArrowLeftDoubleFill />}
        onClick={async () => {
          await onPageChange(FIRST_PAGE)
        }}
        aria-label={t('Pagination.FirstPage')}
        isDisabled={isPreviousPageDisabled}
      />
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        onClick={async () => {
          await onPageChange(currentPage - 1)
        }}
        icon={<RiArrowLeftSLine />}
        aria-label={t('Pagination.PreviousPage')}
        isDisabled={isPreviousPageDisabled}
      />
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        onClick={async () => {
          await onPageChange(currentPage + 1)
        }}
        aria-label={t('Pagination.NextPage')}
        icon={<RiArrowRightSLine />}
        isDisabled={isNextPageDisabled}
      />
      <IconButton
        {...COMMON_ICON_BUTTON_PROPS}
        onClick={async () => {
          await onPageChange(count)
        }}
        aria-label={t('Pagination.LastPage')}
        icon={<RiArrowRightDoubleFill />}
        isDisabled={isNextPageDisabled}
      />
    </Flex>
  )
}
