import { Box, Button, Flex, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { useIsUpToDesktopWidth } from 'components/Breakpoint'
import { Modal } from 'components/Modal'
import { useThemeColors } from 'hooks/useThemeColors'

type InfoPopupProps = {
  /**
   * @description Content to be rendered in the modal (if on desktop) or in the tooltip (if on mobile)
   */
  children: React.ReactNode
  /**
   * @description In case of mobile, the title will be displayed in the modal header
   */
  title: string
  /**
   * @description Custom trigger element for the popup, if not provided, a question mark icon will be rendered
   */
  triggerElement?: React.ReactElement
}

export const InfoPopup = ({ children, title, triggerElement }: InfoPopupProps) => {
  const { t } = useTranslation(['common'])
  const COLORS = useThemeColors()
  const { isUpToDesktopWidth } = useIsUpToDesktopWidth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const targetRef = React.useRef<HTMLDivElement>(null)

  return isUpToDesktopWidth ? (
    <>
      <Box onClick={onOpen} {...(!triggerElement && { boxSize: 4, color: COLORS.grey01 })}>
        {triggerElement || <RiQuestionLine size={16} color="inherit" />}
      </Box>
      <Modal
        variant="infoZircuit"
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        footer={
          <Button w="full" onClick={onClose}>
            {t('OkGotIt')}
          </Button>
        }
      >
        {children}
      </Modal>
    </>
  ) : (
    <Flex>
      {/* Fixes tooltip rendering in modals */}
      <Box ref={targetRef} />
      <Tooltip
        shouldWrapChildren
        label={children}
        my="auto"
        portalProps={{ containerRef: targetRef, appendToParentPortal: true }}
      >
        <Box
          {...(!triggerElement && {
            as: 'span',
            boxSize: 4,
            color: COLORS.grey03,
            _hover: { color: COLORS.grey01, cursor: 'help' },
          })}
        >
          {triggerElement || <RiQuestionLine size={16} color="inherit" />}
        </Box>
      </Tooltip>
    </Flex>
  )
}
