import { useQuery } from '@tanstack/react-query'
import { readContract } from '@wagmi/core'
import { mapLimit } from 'async'
import { ethers, isAddress } from 'ethers'
import { keyBy } from 'lodash'
import React from 'react'
import { erc20Abi, type Address } from 'viem'
import { useAccount, useBalance } from 'wagmi'
import { useErc20TokensQuery } from 'apollo/generated/graphqlClient'
import type { Erc20Token, ExternalErc20Token } from 'apollo/generated/graphqlClient'
import { ASSETS_POLLING_INTERVAL, DECIMALS, DEFAULT_VALUE } from 'constants/common'
import { l1, ETH, zircuit } from 'constants/network'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { wagmiConfig } from 'lib/wagmi'
import type { EthAsset } from 'types/network'
import { captureError } from 'utils/sentry'

export type Erc20TokenAsset = ({ isManual?: boolean } & Erc20Token) | ExternalErc20Token
export type Asset = Erc20TokenAsset | EthAsset
export type AssetWithBalance = { value: string } & Asset

export const useAssets = (): {
  assets: Asset[]
  assetsWithBalance: AssetWithBalance[]
  isFetchingEthBalance: boolean
  ethBalance: string
  areAssetsWithBalanceLoading: boolean
  refetchAssetsWithBalance: () => Promise<void>
  addManualAsset: (asset: Erc20TokenAsset) => Promise<void>
} => {
  const { address, chain: activeChain } = useAccount()
  const [assetsBalances, setAssetsBalances] = React.useState<Record<string, AssetWithBalance>>({})
  const [isManualAssetLoading, setIsManualAssetLoading] = React.useState<boolean>(false)

  const {
    data: ethBalanceData,
    isLoading: isEthBalanceLoading,
    refetch: refetchEthBalance,
    isFetched: isEthBalanceFetched,
  } = useBalance({ address, query: { enabled: !!address } })
  const {
    data: { erc20Tokens = [], externalErc20Tokens = [] } = {},
    loading: areErc20TokensLoading,
  } = useErc20TokensQuery()

  const isL1NetworkSelected = activeChain?.id === l1.id
  const isL2NetworkSelected = activeChain?.id === zircuit.id

  const tokensToFetch = React.useMemo(
    () => [...erc20Tokens, ...externalErc20Tokens],
    [erc20Tokens, externalErc20Tokens],
  )

  const {
    data: erc20TokensBalances,
    isLoading: areErc20TokensBalancesLoading,
    refetch: refetchErc20TokensBalances,
  } = useQuery({
    queryKey: [
      'erc20TokensBalances',
      address,
      isL1NetworkSelected,
      isL2NetworkSelected,
      tokensToFetch,
    ],
    queryFn: async () => {
      const tokensWithBalances = await mapLimit(
        tokensToFetch,
        10,
        async (token: Erc20TokenAsset) => {
          const contractAddress = isL1NetworkSelected
            ? token.contract_address_l1
            : token.contract_address_l2

          const balance =
            isL1NetworkSelected || isL2NetworkSelected
              ? await readContract(wagmiConfig, {
                  abi: erc20Abi,
                  address: contractAddress as Address,
                  functionName: 'balanceOf',
                  args: [address as Address],
                  chainId: activeChain.id,
                })
              : BigInt(0)

          const decimals = isL1NetworkSelected ? token.decimals_l1 : token.decimals_l2

          return {
            ...token,
            value: ethers.formatUnits(balance, decimals).toString(),
          }
        },
      )

      return keyBy(tokensWithBalances, 'symbol')
    },
    enabled: isAddress(address) && tokensToFetch.length > 0 && !areErc20TokensLoading,
  })

  React.useEffect(() => {
    if (erc20TokensBalances) {
      setAssetsBalances((prev) => ({
        ...prev,
        ...erc20TokensBalances,
      }))
    }
  }, [erc20TokensBalances])

  const handleAddManualAsset = async ({
    contract_address_l1,
    contract_address_l2,
    decimals_l1,
    decimals_l2,
    ...token
  }: Erc20TokenAsset) => {
    setIsManualAssetLoading(true)

    const contractAddress = isL1NetworkSelected ? contract_address_l1 : contract_address_l2

    const balance =
      isL1NetworkSelected || isL2NetworkSelected
        ? await readContract(wagmiConfig, {
            abi: erc20Abi,
            address: contractAddress as Address,
            functionName: 'balanceOf',
            args: [address as Address],
          })
        : BigInt(0)

    const decimals = isL1NetworkSelected ? decimals_l1 : decimals_l2

    const newToken = {
      ...token,
      contract_address_l1,
      contract_address_l2,
      decimals_l1,
      decimals_l2,
      value: ethers.formatUnits(balance, decimals).toString(),
      isManual: true,
    } as AssetWithBalance

    setAssetsBalances((prev) => ({
      ...prev,
      [newToken.symbol]: newToken,
    }))

    setIsManualAssetLoading(false)
  }

  React.useEffect(() => {
    if (!isEthBalanceLoading) {
      setAssetsBalances((prev) => ({
        ...prev,
        [ETH.symbol]: {
          ...ETH,
          value: ethers
            .formatUnits(
              ethBalanceData?.value ?? DEFAULT_VALUE,
              ethBalanceData?.decimals ?? DECIMALS,
            )
            .toString(),
        },
      }))
    }
  }, [ethBalanceData, isEthBalanceLoading])

  // Watch for external app balance updates
  React.useEffect(() => {
    if (!address || !activeChain?.id) {
      return () => {}
    }

    const publicClient = isL1NetworkSelected ? publicClientL1 : publicClientL2

    const unwatchEth = publicClient.watchBlocks({
      includeTransactions: false,
      pollingInterval: ASSETS_POLLING_INTERVAL,
      onBlock: async () => {
        try {
          await refetchEthBalance()
        } catch (error) {
          if (error instanceof Error) {
            captureError(error)
          }
        }
      },
    })

    const unwatchTokens = tokensToFetch.map((token) => {
      const contractAddress = isL1NetworkSelected
        ? token.contract_address_l1
        : token.contract_address_l2

      return publicClient.watchContractEvent({
        address: contractAddress as Address,
        abi: erc20Abi,
        eventName: 'Transfer',
        pollingInterval: ASSETS_POLLING_INTERVAL,
        onLogs: async (logs) => {
          const isRelevant = logs.some(
            (log) =>
              log.args.from?.toLowerCase() === address.toLowerCase() ||
              log.args.to?.toLowerCase() === address.toLowerCase(),
          )
          if (isRelevant) {
            try {
              await refetchErc20TokensBalances()
            } catch (error) {
              if (error instanceof Error) {
                captureError(error)
              }
            }
          }
        },
      })
    })

    return () => {
      unwatchEth()
      unwatchTokens.forEach((unwatchFn) => unwatchFn?.())
    }
  }, [
    activeChain?.id,
    address,
    isL1NetworkSelected,
    refetchErc20TokensBalances,
    refetchEthBalance,
    tokensToFetch,
  ])

  const sortedAssets = [...erc20Tokens, ...externalErc20Tokens].reduce<{
    zrcAsset: Erc20TokenAsset | null
    otherAssets: Erc20TokenAsset[]
  }>(
    (acc, asset) => {
      if (asset.symbol === 'ZRC') {
        acc.zrcAsset = asset
      } else {
        acc.otherAssets.push(asset)
      }
      return acc
    },
    { zrcAsset: null, otherAssets: [] },
  )

  const sortedAssetsWithBalance = Object.values(assetsBalances)
    .sort((a, b) => a.id - b.id)
    .reduce<{
      ethAsset: AssetWithBalance | null
      zrcAsset: AssetWithBalance | null
      otherAssets: AssetWithBalance[]
    }>(
      (acc, asset) => {
        if (asset.symbol === 'ETH') {
          acc.ethAsset = asset
        } else if (asset.symbol === 'ZRC') {
          acc.zrcAsset = asset
        } else {
          acc.otherAssets.push(asset)
        }
        return acc
      },
      { ethAsset: null, zrcAsset: null, otherAssets: [] },
    )
  return {
    assets: [
      ETH,
      ...(sortedAssets.zrcAsset ? [sortedAssets.zrcAsset] : []),
      ...sortedAssets.otherAssets,
    ],
    assetsWithBalance: [
      ...(sortedAssetsWithBalance.ethAsset ? [sortedAssetsWithBalance.ethAsset] : []),
      ...(sortedAssetsWithBalance.zrcAsset ? [sortedAssetsWithBalance.zrcAsset] : []),
      ...sortedAssetsWithBalance.otherAssets,
    ], // *: Ensure that ExternalErc20Token assets are at the end of the array & ZRC second
    addManualAsset: handleAddManualAsset,
    ethBalance: ethBalanceData ? ethers.formatEther(ethBalanceData.value) : DEFAULT_VALUE,
    isFetchingEthBalance: isEthBalanceLoading || !isEthBalanceFetched,
    areAssetsWithBalanceLoading:
      isEthBalanceLoading || areErc20TokensBalancesLoading || isManualAssetLoading,
    refetchAssetsWithBalance: async () => {
      await refetchEthBalance()
      await refetchErc20TokensBalances()
    },
  }
}
