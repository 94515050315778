import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useSwitchChain } from 'wagmi'
import { Modal } from 'components/Modal'
import { zircuit } from 'constants/network'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { captureError } from 'utils/sentry'

type SwitchNetworkModalProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  targetNetwork: {
    label: string
    id: number
  }
}

export const SwitchNetworkModal = ({
  isOpen,
  title,
  onClose,
  targetNetwork,
}: SwitchNetworkModalProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const toast = useToast()

  const { switchChain } = useSwitchChain()

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <Flex flexDir="column" alignItems="center">
        <Image src={`/assets/portal2_${colorMode}.png`} />
        <Text mt={7} maxW={280} variant="text2regular" textAlign="center" color={COLORS.grey02}>
          {t('SwitchNetworkModal.SwitchNetworkDescription', {
            action: targetNetwork.id === zircuit.id ? 'withdrawals' : 'deposits',
            networkName: targetNetwork.label,
          })}
        </Text>
        <Button
          mt={8}
          w="100%"
          onClick={() => {
            switchChain(
              { chainId: targetNetwork.id },
              {
                onSuccess: onClose,
                onError: (error) => {
                  captureError(error)

                  if (error.name === 'ChainNotConfiguredError') {
                    toast({ message: t('Error.ChainNotConfigured'), status: 'danger' })
                    return
                  }

                  if (error.name === 'UserRejectedRequestError') {
                    toast({ message: t('Error.UserRejectedRequest'), status: 'danger' })
                    return
                  }

                  toast({ message: t('Error.UnableToSwitchNetwork'), status: 'danger' })
                },
              },
            )
          }}
        >
          {t('SwitchNetworkModal.SwitchNetwork')}
        </Button>
      </Flex>
    </Modal>
  )
}
