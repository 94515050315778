import { getBalance, readContract } from '@wagmi/core'
import { ethers } from 'ethers'
import React from 'react'
import { useAccount } from 'wagmi'
import { l1 } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { useFeatureFlagsContext } from 'providers/FeatureFlagsProvider'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { captureError } from 'utils/sentry'

export const useDepositLimits = () => {
  // These values are always present as we get this value for current environment and not for custom token bridging.
  const { OptimismPortal, OptimismPortalProxy, L1StandardBridge, L1StandardBridgeProxy } =
    getSmartContracts()
  const { isDepositLimitEnabled } = useFeatureFlagsContext()
  const { address } = useAccount()
  const [isAddressDepositCreditLoading, setIsAddressDepositCreditLoading] =
    React.useState<boolean>(true)
  const [isAddressDepositLimitLoading, setIsAddressDepositLimitLoading] =
    React.useState<boolean>(false)
  const [isBridgeDepositCreditAndLimitLoading, setIsBridgeDepositCreditAndLimitLoading] =
    React.useState<boolean>(false)
  const [bridgeDepositLimitPercentage, setBridgeDepositLimitPercentage] =
    React.useState<number>(100) // How much % of the deposit bridge limit is available
  const [bridgeDepositCredit, setBridgeDepositCredit] = React.useState<string>('0') // *: Formatted value in ETH, example: '5.0'
  const [bridgeDepositLimit, setBridgeDepositLimit] = React.useState<string>('0') // *: Formatted value in ETH, example: '5.0'
  const [addressDepositCredit, setAddressDepositCredit] = React.useState<string>('0') // *: Formatted value in ETH, example: '5.0'
  const [addressDepositLimit, setAddressDepositLimit] = React.useState<string>('0') // *: Formatted value in ETH, example: '5.0'

  const handleBridgeDepositCreditAndLimit = React.useCallback(async () => {
    try {
      const data = await readContract(wagmiConfig, {
        address: OptimismPortalProxy!.address,
        abi: OptimismPortal!.abi,
        functionName: 'ethThrottleDeposits',
        chainId: l1.id,
      })

      const optimismPortalProxyBalance = await getBalance(wagmiConfig, {
        address: OptimismPortalProxy!.address,
        chainId: l1.id,
      })

      const remainingBalance = BigInt(data[2]) - optimismPortalProxyBalance.value

      setBridgeDepositLimitPercentage((Number(remainingBalance) * 100) / Number(data[2]))
      setBridgeDepositCredit(ethers.formatEther(remainingBalance.toString()))
      setBridgeDepositLimit(ethers.formatEther(data[2].toString()))
    } catch (error) {
      if (error instanceof Error) {
        captureError(error)
      }
    }
  }, [OptimismPortalProxy!.address, OptimismPortal!.abi])

  const handleAddressDepositCredit = React.useCallback(async () => {
    if (address) {
      try {
        const data = await readContract(wagmiConfig, {
          address: L1StandardBridgeProxy.address,
          abi: L1StandardBridge.abi,
          functionName: 'getEthThrottleDepositsCredits',
          args: [address],
          chainId: l1.id,
        })

        setAddressDepositCredit(ethers.formatEther(data))
      } catch (error) {
        if (error instanceof Error) {
          captureError(error)
        }
      }
    }
  }, [address, L1StandardBridgeProxy.address, L1StandardBridge.abi])

  const handleAddressDepositLimit = React.useCallback(async () => {
    if (address) {
      try {
        const data = await readContract(wagmiConfig, {
          address: L1StandardBridgeProxy.address,
          abi: L1StandardBridge.abi,
          functionName: 'ethThrottleDeposits',
          chainId: l1.id,
        })

        setAddressDepositLimit(ethers.formatEther(data[0]))
      } catch (error) {
        if (error instanceof Error) {
          captureError(error)
        }
      }
    }
  }, [address, L1StandardBridgeProxy.address, L1StandardBridge.abi])

  const fetchBridgeDepositCredit = React.useCallback(async () => {
    setIsBridgeDepositCreditAndLimitLoading(true)
    await handleBridgeDepositCreditAndLimit().finally(() => {
      setIsBridgeDepositCreditAndLimitLoading(false)
    })
  }, [handleBridgeDepositCreditAndLimit])

  const fetchAddressDepositLimit = React.useCallback(async () => {
    setIsAddressDepositLimitLoading(true)
    await handleAddressDepositLimit().finally(() => {
      setIsAddressDepositLimitLoading(false)
    })
  }, [handleAddressDepositLimit])

  const fetchAddressDepositCredit = React.useCallback(async () => {
    setIsAddressDepositCreditLoading(true)
    await handleAddressDepositCredit().finally(() => {
      setIsAddressDepositCreditLoading(false)
    })
  }, [handleAddressDepositCredit])

  React.useEffect(() => {
    if (isDepositLimitEnabled) {
      void fetchBridgeDepositCredit()
    }
  }, [isDepositLimitEnabled, fetchBridgeDepositCredit])

  React.useEffect(() => {
    if (isDepositLimitEnabled) {
      void fetchAddressDepositLimit()
    }
  }, [isDepositLimitEnabled, fetchAddressDepositLimit])

  React.useEffect(() => {
    if (isDepositLimitEnabled) {
      void fetchAddressDepositCredit()
    }
  }, [isDepositLimitEnabled, fetchAddressDepositCredit])

  return {
    isAddressDepositCreditLoading,
    isAddressDepositLimitLoading,
    isBridgeDepositCreditAndLimitLoading,
    bridgeDepositLimitPercentage,
    bridgeDepositCredit,
    bridgeDepositLimit,
    addressDepositCredit,
    addressDepositLimit,
    refetchDepositLimits: async () => {
      await Promise.allSettled([fetchBridgeDepositCredit(), fetchAddressDepositCredit()]) // fetchAddressDepositLimit doesn't need refetch as its payload doesn't change
    },
  }
}
