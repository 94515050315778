import type { FlexProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  useMediaQuery,
  Spinner,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { defaultTo, maxBy } from 'lodash'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { RiArrowDownSLine, RiSearchLine } from 'react-icons/ri'
import { getAddress, type Address, isAddress } from 'viem'
import { useAccount } from 'wagmi'
import { z } from 'zod'
import { AssetItem } from 'components/AssetPicker/AssetItem'
import { AddManualToken } from 'components/AssetPicker/states/AddManualToken'
import { Modal } from 'components/Modal'
import { COLOR_MODE } from 'constants/common'
import type { Asset } from 'hooks/useAssets'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { publicClientL2 } from 'lib/viem'
import { useAssetContext } from 'providers/AssetProvider'
import { validateERC20Token } from 'utils/bridge/validateERC20Token'
import { getL1Erc20TokenAbi, getL2Erc20TokenAbi } from 'utils/getErc20Abi'
import { captureError } from 'utils/sentry'
import { AddAssetButton } from './AddAssetButton'

const addressSchema = z.object({
  l1TokenAddress: z.string().refine((value): value is Address => isAddress(value), {
    message: 'Invalid smart contract address',
  }),
  l2TokenAddress: z.string().refine((value): value is Address => isAddress(value), {
    message: 'Invalid smart contract address',
  }),
})

export type AddressFormInputsType = z.infer<typeof addressSchema>

type AssetPickerProps = {
  value: { id: string | number; label: string }
  onValueChange: (value: Asset) => void
  label?: string
  options: Asset[]
  imageSrc: string
  onIncorrectNetwork: () => void
  pickerStyle?: FlexProps
  iconColor?: string
  textVariant?: string
  modalTitle?: string
  isDisabled?: boolean
  isLoading?: boolean
} & FlexProps

export const AssetPicker = ({
  value,
  onValueChange,
  label,
  options,
  imageSrc,
  onIncorrectNetwork,
  pickerStyle,
  iconColor,
  textVariant = 'title2medium',
  modalTitle,
  isDisabled,
  isLoading,
  ...props
}: AssetPickerProps) => {
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [isSearchFocused, setIsSearchFocused] = React.useState<boolean>(false)
  const COLORS = useThemeColors()
  const { chainId } = useAccount()
  const { colorMode } = useColorMode()
  const isLightMode = colorMode === COLOR_MODE.LIGHT
  const { t } = useTranslation(['common'])
  const [isOpen, setIsOpen] = React.useState(false)
  const [isAddingToken, setIsAddingToken] = React.useState(false)
  const [isTokenValidating, setIsTokenValidating] = React.useState<boolean>(false)
  const formMethods = useForm<AddressFormInputsType>({
    resolver: zodResolver(addressSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const filteredOptions =
    searchValue.length === 0
      ? options
      : options.filter(
          ({ symbol, name }) =>
            symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
            name.toLowerCase().includes(searchValue.toLowerCase()),
        )

  const toast = useToast()
  const { address, isConnected } = useAccount()
  const { addManualAsset } = useAssetContext()

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleResetInputs = () => {
    setIsTokenValidating(false)
    formMethods.reset()
  }

  const handleClose = () => {
    setIsAddingToken(false)
    setIsTokenValidating(false)
    setIsOpen(false)
    setSearchValue('')
    formMethods.reset()
  }

  const handleBackButton = () => {
    setIsAddingToken(false)
    setIsTokenValidating(false)
  }

  const handleAddToken = async ({
    l1TokenAddress,
    l2TokenAddress,
  }: {
    l1TokenAddress: Address
    l2TokenAddress: Address
  }) => {
    setIsTokenValidating(true)

    const isTokenAlreadyAdded = options.find(
      // @ts-expect-error We don't care that eth does not have contract addresses.
      ({ contract_address_l1, contract_address_l2 }) =>
        contract_address_l1 === l1TokenAddress && contract_address_l2 === l2TokenAddress,
    )

    if (isTokenAlreadyAdded) {
      toast({
        status: 'danger',
        message: t('Error.TargetTokenIsAlreadyPresent'),
      })
      handleResetInputs()

      return
    }

    const l1TokenAbi = await getL1Erc20TokenAbi({ l1TokenAddress: l1TokenAddress as Address })
    const l2TokenAbi = await getL2Erc20TokenAbi({ l2TokenAddress: l2TokenAddress as Address })

    if (!l1TokenAbi || !l2TokenAbi) {
      toast({
        status: 'danger',
        message: t('Error.FailedToFetchTokenAbi'),
      })
      handleResetInputs()

      return
    }

    const result = await validateERC20Token({
      address: address as Address,
      l1TokenAddress: l1TokenAddress as Address,
      l2TokenAddress: l2TokenAddress as Address,
      l2TokenAbi,
    })

    if (!result) {
      toast({
        status: 'danger',
        message: t('Error.TargetErc20TokenIsInvalid'),
      })
      handleResetInputs()

      return
    }

    const name = (await publicClientL2.readContract({
      address: l2TokenAddress as Address,
      abi: l2TokenAbi,
      functionName: 'name',
      args: [],
    })) as string

    const newTokenId =
      maxBy(
        // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
        options.filter((option) => option.isManual),
        'id',
      )?.id ?? 1000

    const newToken = {
      name,
      symbol: result.symbol,
      decimals_l1: result.l1TokenDecimals,
      decimals_l2: result.l2TokenDecimals,
      abi_l1: JSON.stringify(l1TokenAbi),
      abi_l2: JSON.stringify(l2TokenAbi),
      contract_address_l1: getAddress(l1TokenAddress),
      contract_address_l2: getAddress(l2TokenAddress),
      id: newTokenId,
      isManual: true,
    }

    await addManualAsset(newToken)
    setIsTokenValidating(false)
    setIsAddingToken(false)
    formMethods.reset()
    toast({
      status: 'success',
      message: t('Success.Erc20TokenWasAddedSuccessfully'),
    })
  }

  const [isMobile] = useMediaQuery('(max-width: 500px)')

  const getModalContent = () => {
    if (isAddingToken) {
      return <AddManualToken />
    }

    return (
      <>
        {isConnected && (
          <Button
            size="big"
            variant="tertiary"
            mb={2}
            w="100%"
            onClick={() => {
              setIsAddingToken(true)
            }}
          >
            {t('AssetPicker.AddToken')}
          </Button>
        )}
        <InputGroup
          mb={2}
          border="1px solid"
          borderRadius={8}
          borderColor={COLORS.grey06}
          // As we use chakra and styling of a group "active" component is not possible, there is a workaround with focus/blur events.
          {...(isSearchFocused && {
            borderColor: COLORS.zircuitLight,
            _hover: { borderColor: 'none' },
          })}
        >
          <InputLeftElement w={12} h="100%">
            <Box boxSize={4} color={COLORS.grey01}>
              <RiSearchLine color="inherit" size={16} />
            </Box>
          </InputLeftElement>
          <Input
            variant="unstyled"
            pl={12}
            placeholder="Search token by name or symbol"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={() => {
              setIsSearchFocused(true)
            }}
            onBlur={() => {
              setIsSearchFocused(false)
            }}
          />
        </InputGroup>
        {filteredOptions.map((option) => (
          <Flex key={option.id} align="center" gap={3} mb={2}>
            <Box
              p={2.5}
              border="1px solid"
              borderRadius={8}
              borderColor={COLORS.white}
              {...(value.id === option.id && {
                borderColor: isLightMode ? 'zircuitLight' : 'darkGrey06',
              })}
              _hover={{ borderColor: isLightMode ? 'grey04' : 'zircuitLight' }}
              cursor="pointer"
              onClick={() => {
                onValueChange(option)
                handleClose()
              }}
              w="full"
            >
              {/* @ts-expect-error - Erc20Token | ExternalErc20Token Union type */}
              <AssetItem {...option} />
            </Box>
            {chainId && (
              <>
                {'__typename' in option ? (
                  <AddAssetButton
                    {...option}
                    chainId={chainId}
                    onIncorrectNetwork={onIncorrectNetwork}
                  />
                ) : (
                  <Box w={8} />
                )}
              </>
            )}
          </Flex>
        ))}
      </>
    )
  }

  return (
    <>
      <Box minW="fit-content" {...props}>
        {label && (
          <Text mb={1.5} variant="text3regular" color={COLORS.grey03}>
            {label}
          </Text>
        )}
        <Flex
          px={{ base: 2, md: 4 }}
          py={{ base: 2, md: 4 }}
          alignItems="center"
          justifyContent="space-between"
          bgColor={COLORS.bgPrimary}
          border="1px solid"
          borderColor={COLORS.grey06}
          borderRadius={8}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          {...(!isDisabled && {
            _hover: { borderColor: isLightMode ? 'beige03' : 'darkGrey03' },
            onClick: handleOpen,
          })}
          {...pickerStyle}
        >
          <Flex gap={{ base: 1, md: 2.5 }} alignItems="center">
            <Image src={imageSrc} {...(isMobile && { boxSize: 6 })} />
            <Text variant={{ base: 'title2medium', md: textVariant }} maxW="6.75rem" isTruncated>
              {isMobile ? value.label.substring(0, 3).toUpperCase() : value.label}
            </Text>
          </Flex>
          <Box mt={0.5} ml={{ base: 2, md: 3 }}>
            <RiArrowDownSLine color={defaultTo(iconColor, COLORS.dark01)} size={20} />
          </Box>
        </Flex>
      </Box>
      <FormProvider {...formMethods}>
        <Modal
          isOpen={isOpen}
          title={modalTitle ?? t('AssetPicker.Select')}
          onClose={handleClose}
          modalBodyProps={{
            maxH: '30rem',
            ...(!isAddingToken && { minH: '30rem' }),
            overflowY: 'auto',
          }}
          {...(isAddingToken && {
            footer: (
              <Flex gap={2}>
                <Button variant="secondary" onClick={handleBackButton}>
                  {t('AssetPicker.Back')}
                </Button>
                <Button
                  isLoading={isTokenValidating}
                  isDisabled={!formMethods.formState.isValid}
                  onClick={async () => {
                    try {
                      await formMethods.handleSubmit(handleAddToken)()
                    } catch (error) {
                      if (error instanceof Error) {
                        captureError(error)
                        toast({
                          status: 'danger',
                          message: t('Error.FailedToAddToken'),
                        })
                      }
                    }
                  }}
                >
                  {t('AssetPicker.AddToken')}
                </Button>
              </Flex>
            ),
          })}
        >
          {isLoading ? (
            <Flex justifyContent="center">
              <Spinner size="sm" color={COLORS.grey02} />
            </Flex>
          ) : (
            getModalContent()
          )}
        </Modal>
      </FormProvider>
    </>
  )
}
