import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { readContract } from '@wagmi/core'
import axios from 'axios'
import { Big } from 'big.js'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { encodeFunctionData } from 'viem'
import { useAccount } from 'wagmi'
import {
  DEFAULT_VALUE,
  OPTIMISTIC_GAS_ESTIMATE_MULTIPLIER,
  PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER,
} from 'constants/common'
import {
  BRIDGE_MIN_GAS_LIMIT_ERC20,
  BRIDGE_MIN_GAS_LIMIT_ETH,
  chainIdProviderMap,
  ETH,
  L2_LAYER_IDS,
} from 'constants/network'
import { publicClientL1 } from 'lib/viem'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { getAdjustedGasAmount } from 'utils/bridge/getAdjustedGasAmount'
import { getDepositERC20Arguments } from 'utils/bridge/getDepositERC20Arguments'
import { getDepositERC20FunctionName } from 'utils/bridge/getDepositERC20FunctionName'
import { getDepositOrWithdrawETHArguments } from 'utils/bridge/getDepositOrWithdrawETHArguments'
import { getDepositOrWithdrawETHFunctionName } from 'utils/bridge/getDepositOrWithdrawETHFunctionName'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { getWithdrawERC20Arguments } from 'utils/bridge/getWithdrawERC20Arguments'
import { getWithdrawERC20FunctionName } from 'utils/bridge/getWithdrawERC20FunctionName'
import {
  getUSDCAdapterAddress,
  isLsETHToken,
  isUSDCToken,
  isUSDTToken,
  isWstETHToken,
} from 'utils/common'

type UseGetGasFeeProps = {
  amountToTransfer: string
  chainId: number
  customReceivingAddress?: Address
  isEnabled: boolean
}

const calculateGasFee = async (provider: ethers.JsonRpcProvider, gasAmount: bigint) => {
  const { gasPrice } = await provider.getFeeData()
  const gasFeeInWei = (gasPrice ?? 0n) * gasAmount
  return ethers.formatEther(gasFeeInWei)
}

export const useGetGasFee = ({
  amountToTransfer,
  chainId,
  customReceivingAddress,
  isEnabled,
}: UseGetGasFeeProps) => {
  const { selectedAsset, selectedAssetBalance } = useAssetContext()
  const { address: addressFrom, isConnected } = useAccount()
  const address = customReceivingAddress ?? (addressFrom as Address)

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === addressFrom

  const isWstETH = isWstETHToken(selectedAsset.symbol)
  const isUSDC = isUSDCToken(selectedAsset.symbol)
  const isLsETH = isLsETHToken(selectedAsset.symbol)
  const isUSDT = isUSDTToken(selectedAsset.symbol)

  // USDC needs to have a non-zero amount to be transferable -> there is an extra check in the contract
  const isUSDCWithZeroAmount = isUSDC && new Big(amountToTransfer || 0).eq(0)

  return useQuery({
    enabled:
      isConnected &&
      new Big(amountToTransfer || 0).lte(selectedAssetBalance) &&
      isEnabled &&
      !isUSDCWithZeroAmount,
    queryKey: ['getGasFee', amountToTransfer, chainId],
    queryFn: async () => {
      const provider = new ethers.JsonRpcProvider(chainIdProviderMap[chainId])

      const isWithdraw = L2_LAYER_IDS.includes(chainId)

      // Estimate gas fee for ETH transaction
      if (selectedAsset.symbol === ETH.symbol) {
        const { L2StandardBridge, L1StandardBridge, L1StandardBridgeProxy, L2StandardBridgeProxy } =
          getSmartContracts()

        const addressTo = isWithdraw ? L2StandardBridgeProxy.address : L1StandardBridgeProxy.address

        const abi = isWithdraw ? L2StandardBridge.abi : L1StandardBridge.abi

        const gasAmount = await provider.estimateGas({
          from: addressFrom,
          to: addressTo,
          data: encodeFunctionData({
            abi,
            functionName: getDepositOrWithdrawETHFunctionName(isSameReceivingAddress),
            // @ts-expect-error - Union type
            args: getDepositOrWithdrawETHArguments(isSameReceivingAddress, {
              to: address,
              minGasLimit: BRIDGE_MIN_GAS_LIMIT_ETH,
              extraData: '0x',
            }),
          }),
          value: ethers.parseEther(amountToTransfer || '0'),
        })

        const calculatedGas = await calculateGasFee(
          provider,
          getAdjustedGasAmount(
            gasAmount,
            isWithdraw ? PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER : OPTIMISTIC_GAS_ESTIMATE_MULTIPLIER,
          ),
        )
        return calculatedGas
      }

      const contractAddressL1 =
        'contract_address_l1' in selectedAsset ? selectedAsset.contract_address_l1 : undefined
      const contractAbiL1 = JSON.parse('abi_l1' in selectedAsset ? selectedAsset.abi_l1 : '[]')
      const contractAddressL2 =
        'contract_address_l2' in selectedAsset ? selectedAsset.contract_address_l2 : undefined
      const contractAbiL2 = JSON.parse('abi_l2' in selectedAsset ? selectedAsset.abi_l2 : '[]')

      // Estimate gas fee for deposit ERC20
      if (!isWithdraw) {
        const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
        const decimals =
          'decimals_l1' in selectedAsset ? selectedAsset.decimals_l1 : selectedAsset.decimals
        const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

        const allowance = (await readContract(wagmiConfig, {
          address: contractAddressL1 as Address,
          abi: contractAbiL1,
          functionName: 'allowance',
          args: [addressFrom as Address, L1StandardBridgeProxy.address],
        })) as bigint

        let gasAmount = BigInt(0)

        if (allowance < bigIntAmount) {
          if (isUSDT) {
            gasAmount = await provider.estimateGas({
              from: addressFrom,
              to: contractAddressL1 as Address,
              data: encodeFunctionData({
                abi: contractAbiL1,
                functionName: 'approve',
                args: [L1StandardBridgeProxy.address, DEFAULT_VALUE],
              }),
            })
            gasAmount *= BigInt(2) // Multiply by 2 because USDT needs to set allowance back to 0 before a new approval
          } else {
            gasAmount = await provider.estimateGas({
              from: addressFrom,
              to: contractAddressL1 as Address,
              data: encodeFunctionData({
                abi: contractAbiL1,
                functionName: 'approve',
                args: [L1StandardBridgeProxy.address, bigIntAmount],
              }),
            })
          }
          gasAmount = getAdjustedGasAmount(gasAmount, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER)
        } else {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: L1StandardBridgeProxy.address,
            data: encodeFunctionData({
              abi: L1StandardBridge.abi,
              functionName: getDepositERC20FunctionName(isWstETH, isSameReceivingAddress),
              // @ts-expect-error - Union type
              args: getDepositERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
                contractAddressL1: contractAddressL1 as Address,
                contractAddressL2: contractAddressL2 as Address,
                to: address,
                amount: bigIntAmount,
                minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
                extraData: '0x',
              }),
            }),
          })
          gasAmount = getAdjustedGasAmount(gasAmount, OPTIMISTIC_GAS_ESTIMATE_MULTIPLIER)
        }

        const calculatedGas = await calculateGasFee(provider, gasAmount)
        return calculatedGas
      }

      if ((isLsETH || isUSDC) && isWithdraw) {
        const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
        // @ts-expect-error - decimals_l2 is available for USDC and WstETH -> TS complains as union type (ETH contains only decimals)
        const decimals = selectedAsset.decimals_l2
        const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

        const allowance = (await readContract(wagmiConfig, {
          address: contractAddressL2 as Address,
          abi: contractAbiL2,
          functionName: 'allowance',
          args: [addressFrom as Address, L2StandardBridgeProxy.address],
        })) as bigint

        let gasAmount = BigInt(0)

        if (allowance < bigIntAmount) {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: contractAddressL2 as Address,
            data: encodeFunctionData({
              abi: contractAbiL2,
              functionName: 'approve',
              args: [
                isUSDC ? getUSDCAdapterAddress() : L2StandardBridgeProxy.address,
                bigIntAmount,
              ],
            }),
          })
          gasAmount = getAdjustedGasAmount(gasAmount, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER)
        } else {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: L2StandardBridgeProxy.address,
            data: encodeFunctionData({
              abi: L2StandardBridge.abi,
              functionName: getWithdrawERC20FunctionName(isWstETH, isSameReceivingAddress),
              // @ts-expect-error - Union type
              args: getWithdrawERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
                contractAddressL2: contractAddressL2 as Address,
                contractAddressL1: contractAddressL1 as Address,
                to: address,
                amount: bigIntAmount,
                minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
                extraData: '0x',
              }),
            }),
          })
          gasAmount = getAdjustedGasAmount(gasAmount, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER)
        }

        const calculatedGas = await calculateGasFee(provider, gasAmount)
        return calculatedGas
      }

      // Estimate gas fee for withdraw ERC20
      const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
      const decimals =
        'decimals_l2' in selectedAsset ? selectedAsset.decimals_l2 : selectedAsset.decimals
      const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

      const gasAmount = await provider.estimateGas({
        from: addressFrom,
        to: L2StandardBridgeProxy.address,
        data: encodeFunctionData({
          abi: L2StandardBridge.abi,
          functionName: getWithdrawERC20FunctionName(isWstETH, isSameReceivingAddress),
          // @ts-expect-error - Union type
          args: getWithdrawERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
            contractAddressL2: contractAddressL2 as Address,
            contractAddressL1: contractAddressL1 as Address,
            to: address,
            amount: bigIntAmount,
            minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
            extraData: '0x',
          }),
        }),
      })

      const calculatedGas = await calculateGasFee(
        provider,
        getAdjustedGasAmount(gasAmount, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
      )
      return calculatedGas
    },
    gcTime: 0,
    /**
     * Gas estimation sometimes fails on the following error:
     * ResourceMetering: too many deposits in this block to fix this we need to retry the query
     */
    retry: 10,
  }) as UseQueryResult<string>
}

export const useGetL1WithdrawalGasFee = () => {
  const { data: ethPrice } = useQuery({
    queryKey: ['getEthValueInUsd', '1'], // Query for 1 ETH price
    queryFn: async () => {
      const { data } = await axios.get<{ ethValueInUsd: string }>('api/ethValueInUsd', {
        params: { value: '1' },
      })
      return data.ethValueInUsd
    },
  })

  return useQuery({
    queryKey: ['getL1WithdrawalGasFee', ethPrice],
    queryFn: async () => {
      const { maxFeePerGas } = await publicClientL1.estimateFeesPerGas()

      // 290 (proving) + 230 (finalizing)
      const gasLimit = 526000
      const estimatedCost = maxFeePerGas * BigInt(gasLimit)
      const estimatedEthCost = ethers.formatEther(estimatedCost)

      const usdCost = ethPrice ? Math.ceil(Number(estimatedEthCost) * Number(ethPrice)) : 0
      return usdCost
    },
    enabled: Boolean(ethPrice),
    gcTime: 0,
    /**
     * Gas estimation sometimes fails on the following error:
     * ResourceMetering: too many deposits in this block to fix this we need to retry the query
     */
    retry: 10,
    refetchInterval: 10_000, // 10 seconds
  }) as UseQueryResult<number>
}
