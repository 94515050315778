import { Link } from '@chakra-ui/next-js'
import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { Announcement } from 'components/Announcement/index'
import { Footer } from 'components/Footer'
import { NavBar } from 'components/NavBar'
import { useThemeColors } from 'hooks/useThemeColors'
import { PAGE_OFFSET_X } from 'theme/constants'

type BaseLayoutProps = {
  children?: React.ReactNode
}

export const BaseLayout = ({ children }: BaseLayoutProps) => {
  const COLORS = useThemeColors()
  const { t } = useTranslation(['common'])

  return (
    <Flex minH="100vh" flexDir="column" justifyContent="space-between">
      <Box bgColor={COLORS.bgSecondary}>
        <NavBar />
        <Announcement>
          {t('MainnetFestivalAnnouncement')}{' '}
          <Link
            href="https://app.zircuit.com/liquidity-hub"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', fontSize: '16px', color: COLORS.black }}
          >
            {t('MainnetFestivalAnnouncementLink')}
          </Link>
        </Announcement>
        <Box
          px={PAGE_OFFSET_X}
          pt={{ base: 4, md: 12 }}
          bgColor={COLORS.bgPrimary}
          borderTopLeftRadius={20}
          borderTopRightRadius={20}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Flex>
  )
}
