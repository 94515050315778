import { Box, Flex, Image, Tag, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'
import { getImageSrc } from 'utils/getImageSrc'

type AssetItemProps = {
  value?: string
  name: string
  symbol: string
  contract_address_l1?: string
  bridge?: boolean
  isManual?: boolean
}

export const AssetItem = ({
  contract_address_l1,
  name,
  symbol,
  value,
  bridge,
  isManual,
}: AssetItemProps) => {
  const { t } = useTranslation(['common'])
  const COLORS = useThemeColors()

  return (
    <Flex
      alignItems={{ base: 'flex-start', md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: 2, md: 0 }}
    >
      <Flex>
        <Image
          src={getImageSrc({ symbol, contract_address_l1, bridge, isManual })}
          borderRadius="full"
        />
        <Box ml={3}>
          <Text variant="title2medium" mb={0.5}>
            {name}
          </Text>
          <Flex alignItems="center" gap={1.5}>
            <Text color={COLORS.grey02} variant="caption2medium">
              {symbol}
            </Text>
            {bridge && <Tag>{t('AssetItem.External')}</Tag>}
            {isManual && <Tag variant="secondary">{t('AssetItem.Manual')}</Tag>}
          </Flex>
          {/* Mobile */}
          <Text
            display={{ base: 'block', md: 'none' }}
            mt={0.5}
            color={COLORS.grey02}
            variant="text2regular"
          >
            {value || 0}
          </Text>
        </Box>
      </Flex>
      {/* Desktop */}
      <Text
        display={{ base: 'none', md: 'block' }}
        ml="auto"
        color={COLORS.grey02}
        variant="text2regular"
      >
        {value || 0}
      </Text>
    </Flex>
  )
}
