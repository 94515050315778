import { Flex, Spinner, Text } from '@chakra-ui/react'
import Big from 'big.js'
import { Trans, useTranslation } from 'next-i18next'
import React from 'react'
import { useAccount } from 'wagmi'
import { Alert } from 'components/Alert'
import { l1 } from 'constants/network'
import { useThemeColors } from 'hooks/useThemeColors'

const WARNING_DEPOSIT_LIMIT_PERCENTAGE_THRESHOLD = 15 as const

type DepositLimitStatesProps = {
  from: {
    id: number
    label: string
  }
  isWarningAlertOpen: boolean
  setIsWarningAlertOpen: React.Dispatch<React.SetStateAction<boolean>>
  isValueGreaterThanDepositLimit: boolean
  doesValueExceedDepositLimit: boolean
  bridgeDepositLimitPercentage: number
  isAddressDepositCreditLoading: boolean
  isAddressDepositLimitLoading: boolean
  isBridgeDepositCreditAndLimitLoading: boolean
  bridgeDepositCredit: string
  bridgeDepositLimit: string
  addressDepositCredit: string
  addressDepositLimit: string
}

export const DepositLimitStates = ({
  from,
  isWarningAlertOpen,
  setIsWarningAlertOpen,
  isValueGreaterThanDepositLimit,
  doesValueExceedDepositLimit,
  bridgeDepositLimitPercentage,
  isAddressDepositCreditLoading,
  isAddressDepositLimitLoading,
  isBridgeDepositCreditAndLimitLoading,
  bridgeDepositCredit,
  bridgeDepositLimit,
  addressDepositCredit,
  addressDepositLimit,
}: DepositLimitStatesProps) => {
  const COLORS = useThemeColors()
  const { t } = useTranslation(['common'])
  const { isConnected } = useAccount()
  const isMounted = React.useRef<boolean>(false)

  const isL1TxEligible = from.id === l1.id && isConnected
  const isL1TxEligibleWithbridgeDepositLimitThreshold =
    isL1TxEligible && bridgeDepositLimitPercentage > 0

  const isWarningShown =
    isL1TxEligibleWithbridgeDepositLimitThreshold &&
    bridgeDepositLimitPercentage <= WARNING_DEPOSIT_LIMIT_PERCENTAGE_THRESHOLD &&
    !isValueGreaterThanDepositLimit &&
    !doesValueExceedDepositLimit

  React.useEffect(() => {
    if (isWarningShown && !isMounted.current) {
      setIsWarningAlertOpen(true)
      isMounted.current = true
    }

    return () => {
      isMounted.current = false
    }
  }, [isWarningShown, setIsWarningAlertOpen])

  return (
    <>
      {isL1TxEligibleWithbridgeDepositLimitThreshold &&
        !isValueGreaterThanDepositLimit &&
        !doesValueExceedDepositLimit && (
          <Flex mt={1} gap={1}>
            <Text variant="text3regular" color={COLORS.grey03}>
              {t('DepositLimits.AddressBridgeLimit')}
            </Text>
            {isAddressDepositCreditLoading || isAddressDepositLimitLoading ? (
              <Spinner size="sm" />
            ) : (
              <Text variant="text3medium">
                {addressDepositCredit}/{addressDepositLimit} ETH
              </Text>
            )}
          </Flex>
        )}
      {isL1TxEligibleWithbridgeDepositLimitThreshold &&
        isValueGreaterThanDepositLimit &&
        !doesValueExceedDepositLimit && (
          <Flex mt={1} gap={1}>
            <Text variant="text3medium" color={COLORS.error}>
              <Trans
                t={t}
                i18nKey="DepositLimits.AmountExceedsAccountLimit"
                components={{
                  AccountLimit: isAddressDepositLimitLoading ? (
                    <Spinner size="sm" color={COLORS.error} />
                  ) : (
                    <span />
                  ),
                }}
                values={{
                  accountLimit: addressDepositCredit,
                }}
              />
            </Text>
          </Flex>
        )}
      {isWarningShown && (
        <Alert
          mt={1}
          variant="warning"
          isOpen={isWarningAlertOpen}
          setIsOpen={setIsWarningAlertOpen}
          hasCloseButton
        >
          <Trans
            t={t}
            i18nKey="DepositLimits.BridgeLimitWarning"
            components={{
              Bold: <span style={{ fontWeight: 500 }} />,
            }}
            values={{
              percentage: '90%',
            }}
          />
        </Alert>
      )}
      {isL1TxEligible && bridgeDepositLimitPercentage === 0 && !doesValueExceedDepositLimit && (
        <Alert mt={2} variant="error">
          {t('DepositLimits.BridgeLimitError')}
        </Alert>
      )}
      {isL1TxEligible && doesValueExceedDepositLimit && (
        <Text mt={1} variant="text3medium" color={COLORS.error}>
          <Trans
            t={t}
            i18nKey="DepositLimits.BridgeLimitExceed"
            components={{
              BridgeLimit: isBridgeDepositCreditAndLimitLoading ? (
                <Spinner size="sm" color={COLORS.error} />
              ) : (
                <span />
              ),
              RemainingBridgeLimit: isBridgeDepositCreditAndLimitLoading ? (
                <Spinner size="sm" color={COLORS.error} />
              ) : (
                <span />
              ),
            }}
            values={{
              bridgeLimit: bridgeDepositLimit,
              remainingBridgeLimit: new Big(bridgeDepositCredit).toFixed(2),
            }}
          />
        </Text>
      )}
    </>
  )
}
