import { Box, Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowRightLine } from 'react-icons/ri'
import { Alert } from 'components/Alert'
import { AmountLabel } from 'components/AmountLabel'
import { ExternalLinkLabel } from 'components/ExternalLinkLabel'
import { Modal } from 'components/Modal'
import { Step, Stepper } from 'components/Stepper'
import {
  ETH,
  EXPECTED_FINALIZATION_PERIOD_TIME_IN_HOURS,
  EXPECTED_FINALIZE_WITHDRAWAL_TIME_IN_SECONDS,
  EXPECTED_PROVE_WITHDRAWAL_TIME_IN_SECONDS,
  EXPECTED_ROLL_UP_DEPOSIT_TIME_IN_SECONDS,
  EXPECTED_SEND_REQUEST_WITHDRAWAL_TIME_IN_SECONDS,
} from 'constants/network'
import { env } from 'env.client'
import type {
  RecentTransactionsListItem as RecentTxItem,
  TransactionListItem as TxItem,
} from 'generated/reactQueryClient'
import { useThemeColors } from 'hooks/useThemeColors'
import { useAssetContext } from 'providers/AssetProvider'
import type { TransactionType } from 'types/network'

type BridgeSuccessModalProps = {
  isOpen: boolean
  onClose: () => void
  onClick?: () => void
  received: string
  txType: TransactionType
  gasFee: number | string
  bridgedTransactionData: RecentTxItem | TxItem | null
}

export const BridgeSuccessModal = ({
  isOpen,
  onClose,
  onClick,
  received,
  gasFee,
  txType,
  bridgedTransactionData,
}: BridgeSuccessModalProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { selectedAsset } = useAssetContext()

  const isWithdrawal = txType === 'withdrawal'

  const expectedTime = isWithdrawal
    ? `~${t('several_hours')}`
    : `~${EXPECTED_ROLL_UP_DEPOSIT_TIME_IN_SECONDS} ${t('seconds')}`

  return (
    <Modal
      isOpen={isOpen}
      title={
        isWithdrawal
          ? t('BridgeSuccessModal.BridgeInProgress')
          : t('BridgeSuccessModal.BridgeInTransitToZircuit')
      }
      onClose={onClose}
      modalContentProps={{ color: COLORS.zircuitPrimary }}
    >
      <Flex
        w="100%"
        justifyContent="center"
        bgColor={COLORS.grey07}
        border="1px solid"
        borderColor={COLORS.grey06}
        borderRadius={10}
      >
        <Image src={`/assets/bridging_success_${colorMode}.png`} />
      </Flex>
      <AmountLabel
        mt={6}
        label={t('BridgeSuccessModal.Received')}
        value={`${received} ${selectedAsset.symbol}`}
      />
      <AmountLabel
        mt={2}
        label={t('BridgeSuccessModal.PaidInGas')}
        value={`${gasFee} ${ETH.symbol}`}
      />
      <Flex flexDirection={isWithdrawal ? 'column' : 'column-reverse'} mt={2} gap={2}>
        <ExternalLinkLabel
          label={t('BridgeSuccessModal.L2Details')}
          linkLabel={t('BridgeSuccessModal.BlockExplorer')}
          {...(bridgedTransactionData?.l2TxHash && {
            href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${bridgedTransactionData.l2TxHash}`,
          })}
        />
        <ExternalLinkLabel
          label={t('BridgeSuccessModal.L1Details')}
          linkLabel={t('BridgeSuccessModal.Etherscan')}
          {...(bridgedTransactionData?.l1TxHash && {
            href: `${env.NEXT_PUBLIC_L1_URL}/tx/${bridgedTransactionData.l1TxHash}`,
          })}
        />
      </Flex>
      {isWithdrawal ? (
        <>
          <Box mt={4} w="100%" h="1px" bgColor={COLORS.grey06} />
          <Stepper mt={6} activeStepIndex={0}>
            <Step
              title={t('BridgeSuccessModal.SendRequest')}
              subTitle={t('BridgeSuccessModal.TimeEstimate', {
                duration: EXPECTED_SEND_REQUEST_WITHDRAWAL_TIME_IN_SECONDS,
                unit: t('seconds'),
              })}
            />
            <Step
              title={t('BridgeSuccessModal.RollUp')}
              subTitle={t('BridgeSuccessModal.TimeEstimate', {
                duration: undefined,
                unit: t('several_hours'),
              })}
            />
            <Step
              title={t('BridgeSuccessModal.ProveWithdrawal')}
              subTitle={t('BridgeSuccessModal.TimeEstimate', {
                duration: EXPECTED_PROVE_WITHDRAWAL_TIME_IN_SECONDS,
                unit: t('seconds'),
              })}
            />
            <Step
              title={t('BridgeSuccessModal.WaitFinalizationPeriod')}
              subTitle={t('hours', { count: EXPECTED_FINALIZATION_PERIOD_TIME_IN_HOURS })}
            />
            <Step
              title={t('BridgeSuccessModal.FinalizeWithdrawal')}
              subTitle={t('BridgeSuccessModal.TimeEstimate', {
                duration: EXPECTED_FINALIZE_WITHDRAWAL_TIME_IN_SECONDS,
                unit: t('seconds'),
              })}
            />
          </Stepper>
          <Alert mt={7} variant="warning">
            {t('BridgeSuccessModal.ZircuitWithdrawalWarning')}
          </Alert>
        </>
      ) : (
        <Flex mt={2}>
          <Text variant="text2regular" color={COLORS.grey02}>
            {t('BridgeSuccessModal.TimeToProceed')}
          </Text>
          <Text ml="auto" variant="text2medium">
            {expectedTime}
          </Text>
        </Flex>
      )}
      <Button
        variant="tertiary"
        mt={6}
        w="100%"
        rightIcon={<RiArrowRightLine />}
        onClick={() => {
          if (onClick) {
            onClick()
          } else {
            onClose()
          }
        }}
      >
        {t('BridgeSuccessModal.SeeMyTransactions')}
      </Button>
    </Modal>
  )
}
