import { ethers } from 'ethers'

export const isMultisigWallet = async (address: string, provider: ethers.JsonRpcProvider) => {
  try {
    // Check if the address has bytecode (i.e., it's a contract)
    const code = await provider.getCode(address)
    if (code === '0x') {
      return false
    }

    // Gnosis Safe ABI method to check for multisig properties
    const safeABI = ['function getOwners() view returns (address[])']

    const contract = new ethers.Contract(address, safeABI, provider)
    // Call `getOwners()` to verify if it's a Gnosis Safe wallet
    await contract.getOwners()
    return true
  } catch {
    return false
  }
}
