import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { CopyToClipboardButton } from 'components/CopyToClipboardButton'
import { Modal } from 'components/Modal'
import { zircuit } from 'constants/network'
import { env } from 'env.client'

type AddManualZircuitNetworkModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const AddManualZircuitNetworkModal = ({
  isOpen,
  onClose,
}: AddManualZircuitNetworkModalProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('AddManualZircuitNetworkModal.Title')}>
      <Flex flexDirection="column" gap={2}>
        <Text variant="text3regular" mb={6}>
          {t('AddManualZircuitNetworkModal.Description')}
        </Text>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('AddManualZircuitNetworkModal.NetworkName')}</Text>
            <Text variant="text3regular">{zircuit.name}</Text>
          </Box>
          <CopyToClipboardButton text={zircuit.name} />
        </Flex>
        <Divider />
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('AddManualZircuitNetworkModal.ChainId')}</Text>
            <Text variant="text3regular">{zircuit.id}</Text>
          </Box>
          <CopyToClipboardButton text={zircuit.id.toString()} />
        </Flex>
        <Divider />
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('AddManualZircuitNetworkModal.RpcUrl')}</Text>
            <Text variant="text3regular">{zircuit.rpcUrls.default.http[0]}</Text>
          </Box>
          <CopyToClipboardButton text={zircuit.rpcUrls.default.http[0]} />
        </Flex>
        <Divider />
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('AddManualZircuitNetworkModal.Symbol')}</Text>
            <Text variant="text3regular">{zircuit.nativeCurrency.symbol}</Text>
          </Box>
          <CopyToClipboardButton text={zircuit.nativeCurrency.symbol} />
        </Flex>
        <Divider />
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('AddManualZircuitNetworkModal.BlockExplorerUrl')}</Text>
            <Text variant="text3regular">{env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}</Text>
          </Box>
          <CopyToClipboardButton text={env.NEXT_PUBLIC_BLOCK_EXPLORER_URL} />
        </Flex>
      </Flex>
    </Modal>
  )
}
